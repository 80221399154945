import {graphql} from "gatsby";
import React from "react";
import ChevronRight from "react-feather/dist/icons/chevron-right";
import {Link as Lx} from "react-scroll";

import FaqQues from "../components/FaqQuestion/FaqQuestion";
import Layout from "../components/Layout";
import StructuredData, {StructuredDataType} from "../components/StructuredData";
import SanityRichText from "../components/commons/SanityRichText";
import "./faq.css";

const Faq = ({data}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];
  const faqData = data.sanityFaqPage._rawFaqs;
  return (
    <Layout seoData={seoData}>
      <StructuredData
        type={StructuredDataType.FAQ}
        data={{
          name: "FAQ",
          list: faqData.reduce(
            (prev, curr) => [
              ...prev,
              ...curr.faqList.map(({faqTitle: q, faqContent}) => ({
                q,
                a: faqContent.map((e) => e.children[0].text).join("\n"),
              })),
            ],
            []
          ),
        }}
      />
      <div className='intro mt-24 mx-auto'>
        <SanityRichText blocks={data.sanityFaqPage._rawFaqTitle} />
      </div>
      <div className='categoryWrap full-bleed layouted'>
        <h2 className='bold'>Browse the FAQs</h2>
        <div className='categories'>
          {faqData.map((fc) => (
            <Lx className='faqCategory' to={fc._key} spy smooth>
              <img src={fc.faqCategoryImage} alt={fc.faqCategoryTitle} />
              <div className='faqTitle'>
                {fc.faqCategoryTitle} <ChevronRight />
              </div>
            </Lx>
          ))}
        </div>
      </div>
      <div className='quesWrap'>
        {faqData.map((f) => (
          <div className='faqList'>
            <div className='faqTitle' id={f._key}>
              {f.faqCategoryTitle}
            </div>
            {f.faqList.map((fq) => (
              <FaqQues title={fq.faqTitle} content={fq.faqContent} />
            ))}
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Faq;

export const query = graphql`
  {
    allSanitySeoMeta(filter: {page: {eq: "mason faq"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    sanityFaqPage {
      _rawFaqTitle
      _rawFaqs
    }
  }
`;
