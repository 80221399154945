import React, {useState} from "react";
import Minus from "react-feather/dist/icons/minus";
import Plus from "react-feather/dist/icons/plus";

import SanityRichText from "../commons/SanityRichText";
import "./faqQues.css";

const FaqQues = ({title, content}) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={`ques ${isOpen ? "open" : ""}`}>
      <div className='qContent' onClick={() => setOpen(!isOpen)}>
        <div className='qTitle'>
          <h3 className='medium'>{title}</h3>
          {isOpen ? <Minus /> : <Plus />}
        </div>
        {isOpen && (
          <div className='qAns'>
            <SanityRichText className='blocks' blocks={content} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FaqQues;
