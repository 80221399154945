import {isUndefined} from "@kubric/utils";
import {useEffect, useRef, useState} from "react";

export const useIsAmplitudeReady = () => {
  const [loading, setLoading] = useState(true);
  const intervalRef = useRef(-1);
  const timerRef = useRef(-1);
  const cleanup = () => {
    clearInterval(intervalRef.current);
    clearTimeout(timerRef.current);
  };
  useEffect(() => {
    if (typeof window !== "undefined" && intervalRef.current < 0 && loading) {
      // Check if amplitude is loaded periodically every second
      intervalRef.current = setInterval(() => {
        if (!isUndefined(window.amplitude)) {
          cleanup();
          setLoading(false);
        }
      }, 1000);
      // Stop checking if it does not happen in 10 secs
      timerRef.current = setTimeout(cleanup, 10000);
    }
  }, [loading]);
  return !loading;
};
