// Use this component after any other Helmet to overwrite sanity data
import {isNull, isValidString} from "@kubric/utils";
import React from "react";
import {Helmet} from "react-helmet";

const SeoMetaStuff = ({
  // data received from sanity
  data,
  // any other extra tags
  children,
}) => {
  const {title, canUrl, tags = []} = data;

  // if title not defined, then do not update it
  const helmetProps = title && isValidString(title) ? {title} : {};

  return (
    <Helmet {...helmetProps} htmlAttributes={{lang: "en"}}>
      {canUrl && <link rel='canonical' href={canUrl} />}

      {tags.length &&
        tags.map(({type, content}) => {
          if (isNull(type)) {
            return null;
          }
          const {attributeType, attributeValue} = type;
          return (
            <meta {...{[attributeType]: attributeValue}} content={content} />
          );
        })}
      {children}
    </Helmet>
  );
};

export default SeoMetaStuff;
