import * as React from "react";

export const useIsMMTrackerReady = () => {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const messageHandler = (e) => {
        const {origin, data: {type} = {}} = e;
        const isReady = origin === process.env.MM_URL && type === "ready";
        if (isReady) {
          setLoading(false);
          window.removeEventListener("message", messageHandler);
        }
      };
      if (loading) {
        window.addEventListener("message", messageHandler);
      }
    }
  }, []);
  return !loading;
};
