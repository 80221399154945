import {get} from "@kubric/utils";
import {useLocation} from "@reach/router";
import {graphql, useStaticQuery} from "gatsby";
import React from "react";

import {useIsAmplitudeReady} from "../hooks/useIsAmplitudeReady";
import {useIsGoogleTagManagerReady} from "../hooks/useIsGoogleTagManagerReady";
import {useIsMMTrackerReady} from "../hooks/useIsMMTrackerReady";
import {MMTrackFrameId} from "../lib/constants";
import {getCookie} from "../utils/cookie";
import Footer from "./Footer/Footer";
import Header from "./HeaderNew";
import SeoMetaStuff from "./SeoMetaStuff";

const BANNER_QUERY = graphql`
  {
    allSanityBanner {
      nodes {
        allowedPages {
          canUrl
        }
        cta
        ctaID
        image {
          image {
            asset {
              gatsbyImageData
              url
            }
          }
        }
        mImage {
          image {
            asset {
              gatsbyImageData
              url
            }
          }
        }
        show
        text
        url
      }
    }
  }
`;

const Layout = ({
  type = "mason",
  children,
  headerProps = {},
  seoData,
  mmv3,
  className,
  contentBuffer = true,
  hideHeaderBackground = false,
  darkMode = false,
  hideHamMenuOnMobile = false,
  noCTAHeader = false,
  masonLogo = false,
  plainFooterBackground = false,
}) => {
  const location = useLocation();
  const isTrackerReady = useIsMMTrackerReady();
  const isAmplitudeReady = useIsAmplitudeReady();
  const isGTMReady = useIsGoogleTagManagerReady();
  const isFeed = location.pathname.includes("/feed");
  const isBlogPost = location.pathname.includes("/blog/post");

  const data = useStaticQuery(BANNER_QUERY);
  const {allowedPages = [], ...banner} = get(
    data,
    "allSanityBanner.nodes.0",
    {}
  );

  // show the banner when current url is present in allowed urls
  const showMMV3Banner = React.useMemo(
    () =>
      allowedPages.some(({canUrl}) => {
        const currentUrl = location.pathname.endsWith("/")
          ? location.pathname.slice(0, -1)
          : location.pathname;
        const allowedUrl = canUrl.endsWith("/") ? canUrl.slice(0, -1) : canUrl;
        return currentUrl === allowedUrl.split("https://getmason.io")[1];
      }),
    [location?.pathname, allowedPages.length]
  );

  // deeplink "#openIntercom" to open intercom in any page
  React.useEffect(() => {
    if (
      location.hash === "#openIntercom" &&
      typeof window.Intercom === "function"
    ) {
      window.location.hash = "";
      window.Intercom("show");
    }
  }, [location?.hash]);

  React.useEffect(() => {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("style", "display: none;");
    iframe.src = `${process.env.MM_URL}/track`;
    iframe.referrerPolicy = "origin";
    iframe.id = MMTrackFrameId;
    const body = document.querySelector("body");
    body.appendChild(iframe);
  }, []);
  React.useEffect(() => {
    if (isTrackerReady && isAmplitudeReady && isGTMReady) {
      const iframe = document.getElementById(MMTrackFrameId);
      const gaId = getCookie("_ga");
      const deviceId = window.amplitude.getInstance().options.deviceId;
      iframe.contentWindow.postMessage(
        {
          type: "analyticsId",
          data: {
            id: deviceId,
            gaId,
          },
        },
        process.env.MM_URL
      );
    }
  }, [isTrackerReady, isAmplitudeReady, isGTMReady]);

  return (
    <div id='top' className={className}>
      {seoData && <SeoMetaStuff data={seoData} />}
      <div
        className={`siteWrap withHeader ${isFeed ? "feed" : ""} ${
          isBlogPost ? "blogPost" : ""
        }`}
      >
        <Header
          type={type}
          {...headerProps}
          mmv3={mmv3}
          banner={{...banner, showMMV3Banner}}
          hideHamMenuOnMobile={hideHamMenuOnMobile}
          hideHeaderBackground={hideHeaderBackground}
          darkMode={darkMode}
          noCTAHeader={noCTAHeader}
          masonLogo
        />
      </div>
      <div
        className={`siteWrap withoutHeader ${isFeed ? "feed" : ""} ${
          isBlogPost ? "blogPost" : ""
        }`}
      >
        <div
          className={`${contentBuffer ? "contentBuffer" : "noContentBuffer"} ${
            showMMV3Banner ? " withBanner" : ""
          }`}
        />
        {children}
        <Footer
          type={type}
          mmv3={mmv3}
          plainFooterBackground={plainFooterBackground}
        />
      </div>
    </div>
  );
};

export default Layout;
